/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {
        const slides = document.querySelectorAll('#page-home .funds .swiper-slide').length;
        // console.log(slides);

        new Swiper('#page-home .swiper-container', {
            slidesPerView: 1.1,
            draggable: true,
            mousewheel: {
                forceToAxis: true,
            },

            breakpoints: {
                768: {
                    slidesPerView: 1.6,
                },
                1024: {
                    slidesPerView: slides > 2 ? 2.1 : 2,
                },
                1200: {
                    slidesPerView: slides > 2 ? 2.4 : 2,
                }
            }
        });

    }
}
