/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, TimelineMax, gsap, Menu) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $document = $(document),
            $body = $('body'),
            $pageLoader = $('.page-loader'),
            $header = $('#header'),
            menuWrapper = document.querySelector('#mobile-menu'),
            menuButton = document.querySelector('#header .btn-menu'),
            menuBars = menuButton.querySelectorAll('.item-burger > span'),
            menuStaggerItems = menuWrapper.querySelectorAll('[data-stagger-item]')
            ;

        /*
        |
        | Loader
        |---------
        */
        if (sessionStorage.getItem('loaded_once') === null) {
            sessionStorage.setItem('loaded_once', 'loaded_once');
        }

        if ($pageLoader.hasClass('active')) {

            $(window).on('load', function () {
                gsap.to($pageLoader.find('.item-loadbar-inner'), 0.4, { scaleX: 1, ease: 'Power0.easeNone' }, 'start')
                gsap.to($pageLoader.find('.item-content'), 0.8, { autoAlpha: 0, ease: 'Power1.easeOut' }, '-=0')
                // gsap.addCallback(() => { app.dispachEvent($body, 'loaderEnd'); })
                gsap.to($pageLoader, 0.8, { autoAlpha: 0, ease: 'Power1.easeOut' }, '-=0')
            });
        } else {
            app.dispachEvent($body, 'loaderEnd');
        }

        /*
        |
        | Menu au scroll
        |-------
        */
        $document.scroll(function () {
            if ($document.scrollTop() >= 60) {
                $header.addClass('is-scroll');
            } else {
                $header.removeClass('is-scroll');
            }
        });

        /*
        |
        | Menu
        |-------
        */

        // menuButton.on('click', function (e) {
        //     $header.toggleClass('is-open');
        // })

        const menu = new Menu(menuWrapper, menuButton, { reverseTimeScale: 2 });
        menu.menuTimeline
            .to(menuBars[1], 0.3, { autoAlpha: 0 }, 'start')
            .to(menuBars[0], 0.5, { x: 0, y: 8, rotation: 45, ease: 'Power1.easeOut' }, 'start')
            .to(menuBars[2], 0.5, { x: 0, y: -8, rotation: -45, ease: 'Power1.easeOut' }, 'start')
            .to(menuWrapper, 0.5, { autoAlpha: 1, ease: 'Power1.easeOut' }, 'start+=0.3')
            .staggerFrom(menuStaggerItems, 0.6, { autoAlpha: 0, x: 20, ease: 'Power1.easeOut' }, '0.1', '+=0')

        menu.init();


    }
}
