/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper, customGoogleMap) => {
        /*
        |
        | Constants
        |------------
        */
        const
        $map            = $('#map'),
        $body           = $('body'),
        $sContainer     = $('.swiper-container'),
        $cross          = $('.cross-container'),
        $cursor         = $('.cursor-container'),
        $sSlide         = $('.swiper-slide')
        ;

          /*
        |
        | Mouse Follow
        |-------
        */
        // $body.on('mousemove', function (e) {
        //     TweenMax.to($cursor, 0.8, {
        //         css: {
        //             left: e.pageX,
        //             top: e.pageY
        //         },
        //         ease: Power4.easeOut
        //     })
        // })

    //     $sContainer.on('mouseenter', function (e) {
    //         $body.addClass('is-cursor');
    //     })

    //     $sContainer.on('mouseleave', function (e) {
    //        $body.removeClass('is-cursor');
    //    })

        /*
        |
        | Swiper team
        |----------------
        |
        */
        var swiper = new Swiper('.swiper-container', {
            // loop: true,
            spaceBetween: 20,
            grabCursor: true,
            slidesPerView: 1,

            pagination: {
                el: '.swiper-pagination',
                type: 'custom',
                renderCustom: function (swiper, current, total) {
                    return '<h5 class="title-md">' + ('0' + current).slice(-2) + '</h5>' + '<hr>' + '<h5 class="title-md">' + ('0' + total).slice(-2) + '</h5>';
                }
            },

            scrollbar: {
                el: '.swiper-scrollbar',
            },

            navigation: {
                nextEl: '.swiper-next',
                prevEl: '.swiper-prev',
            },

            breakpoints: {
                768: {
                    slidesPerView: 1,
                    spaceBetween: 40,
                },
                992: {
                    slidesPerView: 1,
                    grabCursor: true,
                    slidesPerView: 'auto',
                    spaceBetween: 30,
                },
                1200: {
                    slidesPerView: 1,
                    grabCursor: true,
                    slidesPerView: 'auto',
                    spaceBetween: 30,
                },
            }
        })


        /*
        |
        | initGoogleMap
        |----------------
        |
        */
        $.each($map, function () {
            var $map = $(this);

            var map = new customGoogleMap($map, {
                'markers': $map.find('.marker'),
                'zoom': 8 
            });
        });
    }
}
