/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $cookieBanner = $('.cookie-banner'),
            $itemChild = $('.menu-item-has-children .item-link'),
            $cookieClose = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse')
            ;




            $itemChild.on('click', function (e) {
                e.preventDefault();
                $(this).parent().toggleClass('active')
            })
    

        /*
        |
        | Cookie Manager
        |-----------------
        // */
        // new CookieManager($cookieBanner, {
        //     name: 'adveris_cookie_use',
        //     duration: 30,
        //     buttons: {
        //         accept: $cookieAccept,
        //         refuse: $cookieRefuse,
        //         close: $cookieClose
        //     },
        //     onAccept: () => {
        //         console.log('accpeted')
        //     },
        //     onRefuse: () => {
        //         console.log('refused')
        //     }
        // });

        $body.on('loaderEnd', () => console.log('ended'))
    }
}
