/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
require('@lib/iziModal/js/iziModal.js')($); //désolé
import Swiper from 'swiper/js/swiper.min';
import { TweenMax, TimelineMax, gsap } from "gsap";
import Chart from 'chart.js';



/*
|
| Importing Components
|-----------------------
*/
// import CookieManager from '@components/cookie-manager';
import customGoogleMap from '@components/custom-google-map.js';
import MaterializeForm from '@components/materialize-form.js';
import Menu from '@components/menu.js';
import dispatcher from '@components/dispatcher.js';

/*
|
| Importing Utils
|-------------------
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import news from '@pages/news.js';
import animations from '@pages/animations.js';
import sample from '@pages/sample.js';
import contact from '@pages/contact.js';
import team from '@pages/team.js';
import projects from '@pages/projects.js';
import project from '@pages/project.js';
import funds from '@pages/funds.js';
import dashboard from '@pages/dashboard.js';
import home from '@pages/home.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
  {
    'file': animations,
    'dependencies': [app, TimelineMax, gsap, Menu]
  },
  {
    'file': main,
    'dependencies': [app]
  },
  {
    'file': news,
    'dependencies': [app],
    'resolve': '#page-news-archive'
  },
  {
    'file': sample,
    'dependencies': [app],
    'resolve': '#page-sample'
  },
  {
    'file': contact,
    'dependencies': [TweenMax, gsap, app, MaterializeForm],
    'resolve': '#page-contact'
  },
  {
    'file': home,
    'dependencies': [app, Swiper],
    'resolve': '#page-home'
  },
  {
    'file': team,
    'dependencies': [app, Swiper],
    'resolve': '#page-team'
  },
  {
    'file': projects,
    'dependencies': [app, customGoogleMap],
    'resolve': '#page-projects-archive'
  },
  {
    'file': project,
    'dependencies': [app, Swiper, customGoogleMap],
    'resolve': '#project-single'
  },
  {
    'file': funds,
    'dependencies': [app, customGoogleMap, Chart],
    'resolve': '#page-funds'
  },
  {
    'file': dashboard,
    'dependencies': [app, Chart],
    'resolve': '#page-dashboard'
  },
  {
    'file': dispatcher,
    'dependencies': [app],
  }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

