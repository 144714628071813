/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, customGoogleMap, Chart) => {
        /*
        |
        | Constants
        |------------
        */
        const
            $map = $('#map'),
            $key = $('.key-item')
            ;


        /*
        |
        | Charts
        |----------------
        |
        */

        $key.each(function () {

            const $ctx = $(this).find('.chart')
            const $overview = $(this).find('.over-name')
            var $ArrName = [];
            var $ArrData = [];

            $overview.each(function () {
                const $name = $(this).text();
                const $data = $(this).data('number');

                $ArrName.push($name);
                $ArrData.push($data);
            })

            var myChart = new Chart($ctx, {
                type: 'doughnut',
                data: {
                    labels: $ArrName,
                    datasets: [{
                        data: $ArrData,
                        backgroundColor: [
                            '#3399CC',
                            '#545454',
                            '#365396',
                            '#2768BF',
                            '#3399CC',
                            '#3399CC',
                            '#3399CC',
                            '#3399CC',
                        ],
                        borderColor: [
                            '#0A1721',
                            '#0A1721',
                            '#0A1721',
                            '#0A1721',
                            '#0A1721',
                            '#0A1721',
                            '#0A1721',
                            '#0A1721',
                            '#0A1721',
                            '#0A1721',
                            '#0A1721',
                            '#0A1721'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    legend: {
                        labels: {
                            fontColor: 'white'
                        },
                        position: 'left'
                    },
                    tooltips: {
                        enabled: true,
                        callbacks: {
                            title: function (tooltipItem, data) {
                                return data['labels'][tooltipItem[0]['index']];
                            },
                            label: function (tooltipItem, data) {
                                var dataset = data['datasets'][0];
                                var percent = Math.round((dataset['data'][tooltipItem['index']]));
                                return percent + '%';
                            }
                        },
                    }
                }
            })
        })

        /*
        |
        | initGoogleMap
        |----------------
        |
        */
        $.each($map, function () {
            var $map = $(this);
            console.log('map loaded')
            var map = new customGoogleMap($map, {
                'markers': $map.find('.marker'),
                'zoom': 16
            });
        });
    }
}
