/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {
        const dispatcher = document.querySelector('.dispatcher')
        const dispatcherForm = dispatcher.querySelector('.dispatcher-form')
        const dispatcherProfile = dispatcherForm.querySelector('[name="profile"]')
        const dispatcherLanguage = dispatcherForm.querySelector('[name="language"]')
        let dispatcherXHR

        if(localStorage.getItem('profile') && localStorage.getItem('language')) {
            dispatcherProfile.value = localStorage.getItem('profile')
            dispatcherLanguage.value = localStorage.getItem('language')

            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                'profil': localStorage.getItem('profile')
            })
        } else {
            dispatcher.dataset.opened = true
        }

        addEventListener('click', e => {
            if(!e.target.closest('[data-open-dispatcher]')) return

            dispatcher.dataset.opened = true
        })

        dispatcherForm.addEventListener('submit', e => {
            e.preventDefault()

            dispatcherForm.querySelectorAll('.dispatcher-error').forEach(error => error.remove())

            dispatcherForm.dataset.loading = true

            if(dispatcherXHR) dispatcherXHR.abort()

            dispatcherXHR = $.ajax({
                url: dispatcherForm.getAttribute('action'),
                method: dispatcherForm.getAttribute('method'),
                data: $(dispatcherForm).serialize(),
                dataType: 'json',
                success: response => {
                    if(response.type === 'success') {
                        app.setCookie('profile', response.profile, 365)
                        localStorage.setItem('profile', response.profile)
                        localStorage.setItem('language', response.language)

                        location.href = response.redirect
                        return
                    }

                    for(const [name, value] of Object.entries(response.errors)) {
                        const item = dispatcherForm.querySelector(`[name="${name}"]`).parentElement
                        const error = document.createElement('span')

                        error.classList.add('dispatcher-error')
                        error.textContent = value

                        item.appendChild(error)
                    }

                    dispatcherForm.dataset.loading = false
                }
            })
        })
    }
}
