/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app) => {

        /*
        |
        | Constants
        |------------
        */
        const
            $body = $('body'),
            $barbaContainer = $('.site-container'),
            $postsContainer = $barbaContainer.find('.loaded-posts'),
            $itemFilter = $(".custom-option"),
            $loadingContainer = $barbaContainer.find('.loader-container'),
            $loadMore = $barbaContainer.find('.item-load-more'),
            $loader = $barbaContainer.find('#news-loader'),
            $loaderLoadMore = $barbaContainer.find('#news-loader-load-more'),
            total = $barbaContainer.find('.row-article').length
            ;


            for (const dropdown of document.querySelectorAll(".custom-select-wrapper")) {
                dropdown.addEventListener('click', function () {
                    this.querySelector('.custom-select').classList.toggle('open');
                })
            }
            
            for (const option of document.querySelectorAll(".custom-option")) {
                option.addEventListener('click', function () {
                    if (!this.classList.contains('selected')) {
                        this.parentNode.querySelector('.custom-option.selected').classList.remove('selected');
                        this.classList.add('selected');
                        this.closest('.custom-select').querySelector('.custom-select__trigger span').textContent = this.textContent;
                    }
                })
            }
          
            window.addEventListener('click', function (e) {
                for (const select of document.querySelectorAll('.custom-select')) {
                    if (!select.contains(e.target)) {
                        select.classList.remove('open');
                    }
                }
            });



        /*
        |
        | Variables
        |------------
        */
        let
        xhr = null,
        state = {
            categoryId: 'all',
            categoryName: null,
            offset: total
        }
        ;
    
        
        /*
        |
        | Filtering
        |-----------
        */
        $itemFilter.on('click', function (e) {
            e.preventDefault();

            const $item = $(this);

            $itemFilter.removeClass('active');
            $item.addClass('active');

            state.categoryId = $item.data('category-id');
            state.categoryName = $item.text();
            console.log($item)
            ajaxFilter();
        });

        /*
        |
        | load more
        |------------
        */
        $loadMore.on('click', function (e) {
            e.preventDefault();

            const $item = $(this);

            state.offset = $barbaContainer.find('.row-article').length;

            console.log(state.offset)

            ajaxFilter('loadMore')
        })


        function ajaxFilter(mode = 'filter') {
            if (xhr !== null) {
                xhr.abort()
            }

            const loadmore = mode === 'loadMore';
            const offset = loadmore ? state.offset : 0;

            const route = `/ajax/posts/category/${document.documentElement.lang.split('-')[0]}/${state.categoryId}/${offset}`;

            console.log(route)

            xhr = $.ajax({
                url: route,
                type: 'GET',
                dataType: 'json',
                beforeSend: () => {
                    if (!loadmore) {
                        $postsContainer.fadeOut(400, function () {
                            $(this).html('');
                            $loader.fadeIn(400);
                        });
                    } else {
                        $loadMore.fadeOut(400, function () {
                            $loaderLoadMore.fadeIn(400);
                        });
                    }

                },
                success: (response, status) => {
                    $loader.fadeOut(400);

                    if (loadmore) {
                        $postsContainer.append(response);
                    } else {
                        $postsContainer.html(response).show();
                        $loadMore.hide();
                    }

                    const tl = new TimelineMax({
                        paused: true,
                        onComplete: () => {
                        }
                    })
                    tl.staggerFrom($postsContainer.find('[data-article-item].loaded'), 0.8, {
                        autoAlpha: 0,
                        y: 30,
                        ease: Power1.easeOut
                    }, 0.1);
                    tl.play();
                    $postsContainer.find('[data-article-item].loaded').removeClass('loaded')
                    $loader.hide();
                    $loaderLoadMore.hide();
                   
                    if (!$postsContainer.find('.no-more-post').length) {
                        $loadMore.show();
                    } else{
                        $loadMore.hide();
                    }
                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(response, status, error);
                }
            })
        }
    }
}

