/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, customGoogleMap) => {
        /*
        |
        | Constants
        |------------
        */
        const
            $map = $('#map'),
            $body = $('body')
            ;

        /*
        |
        | initGoogleMap
        |----------------
        |
        */
        $.each($map, function () {
            var $map = $(this);

            var map = new customGoogleMap($map, {
                'markers': $map.find('.marker'),
                'zoom': 13
            });
        });


    }
}
