/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Chart) => {

        /*
        |
        | Charts
        |----------------
        |
        */
        const $graphicSingle = $(".graphic-single");
        const datasClasses = $('[data-classes]').data('classes');
        const selectedClass = $('.section-select .custom-option.selected').data('title');

        $('.data-bid').each(function (index) {
            var content = $(this).data('value');
            const contentReplaced = content.toString().replace(',', '.');
            const contentParsed = Number.parseFloat(contentReplaced);
            const contentFormated = contentParsed.toFixed(2)

            $(this).text(contentFormated);
        })

        // let arrDates = ['2016'];
        // let arrPrices = [60];

        // $.each(datasClasses, (k, v) => {
        //     arrDates.push(v.share_price_title);
        //     arrPrices.push(parseFloat(v.share_price_data_1_figure));
        // });

        const selectedClasses = datasClasses.filter(object => object.post_title == selectedClass);
        const selectedArr = selectedClasses.map(object => {
            return {
                launch_date: object.share_class_content,
                date_string: object.share_price_title,
                price: parseInt(object.share_price_data_1_figure),
                date: object.class_date
            }
        });
        selectedArr.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))
        const selectedArrD = selectedArr.map(object => object.date);
        const selectedArrP = selectedArr.map(object => parseInt(object.price));
        console.log(selectedArr[0]);
        //$('.cms-launch-date').html(selectedArr[0].launch_date);
        //$('.launch-date').text(selectedArr[0].date_string.replace('As of', ''));
        //$('.current-share-date').text(selectedArr[selectedArr.length - 1].date_string);

        var ctx = $graphicSingle.find('.in-chart');
        var myChart = null;

        updatechart(selectedArrD, selectedArrP);


        /*
        |
        | Selector
        |----------------
        |
        */
        for (const dropdown of document.querySelectorAll(".data-select-wrapper")) {
            dropdown.addEventListener('click', function () {
                this.querySelector('.data-select').classList.toggle('open');
            })
        }

        var dataSelector

        for (const option of document.querySelectorAll(".data-option")) {
            option.addEventListener('click', function () {
                if (!this.classList.contains('selected')) {

                    const title = option.dataset.title;
                    const filteredClasses = datasClasses.filter(object => object.post_title == title);
                    const arr = filteredClasses.map(object => {
                        return {
                            launch_date: object.share_class_content,
                            date_string: object.share_price_title,
                            price: parseInt(object.share_price_data_1_figure),
                            date: object.class_date
                        }
                    });
                    arr.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0))
                    const arrD = arr.map(object => object.date);
                    const arrP = arr.map(object => parseInt(object.price));
                    console.log(arr[0]);
                    //$('.cms-launch-date').html(arr[0].launch_date);
                    //$('.current-share-date').text(arr[arr.length - 1].date_string);

                    updatechart(arrD, arrP);

                    dataSelector = $(this).data('value')

                    $(".share-content").removeClass('is-active')
                    $(".share-content").each(function () {
                        const dataContent = $(this).data('value')
                        if (dataSelector == dataContent) {
                            $(this).addClass('is-active')
                        }
                    })

                    this.parentNode.querySelector('.data-option.selected').classList.remove('selected');
                    this.classList.add('selected');
                    this.closest('.data-select').querySelector('.data-select__trigger span').textContent = this.textContent;
                }
            })
        }
        window.addEventListener('click', function (e) {
            for (const select of document.querySelectorAll('.data-select')) {
                if (!select.contains(e.target)) {
                    select.classList.remove('open');
                }
            }
        });


        function updatechart(dates, prices) {

            if (myChart !== null) {
                myChart.destroy();
            }

            myChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: dates, //ArrDate
                    datasets: [{
                        label: 'Price',
                        data: prices, //$ArrPrice
                        backgroundColor: [
                            '#2E4B5E'
                        ],
                        borderColor: [
                            'rgba(88, 140, 172, 1)'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    legend: {
                        labels: {
                            fontColor: "white"
                        }
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                fontColor: "white"
                            }
                        }],
                        xAxes: [{
                            ticks: {
                                fontColor: "white"
                            }
                        }]
                    }
                }
            });
        }


        /*
        |
        | Selector Report
        |----------------
        |
        */
        for (const dropdown of document.querySelectorAll(".report-wrapper")) {
            dropdown.addEventListener('click', function () {
                this.querySelector('.report-select').classList.toggle('open');
            })
        }

        for (const option of document.querySelectorAll(".report-option")) {
            option.addEventListener('click', function () {
                if (!this.classList.contains('selected')) {

                    var dataSelector = $(this).data('value')

                    $(".report-single").removeClass('is-active')

                    $(".report-single").each(function () {
                        var dataContent = $(this).data('value')
                        if (dataSelector == dataContent) {
                            $(this).addClass('is-active')
                        }
                    })

                    this.parentNode.querySelector('.report-option.selected').classList.remove('selected');
                    this.classList.add('selected');
                    this.closest('.report-select').querySelector('.report-select__trigger span').textContent = this.textContent;
                }
            })
        }
        window.addEventListener('click', function (e) {
            for (const select of document.querySelectorAll('.report-select')) {
                if (!select.contains(e.target)) {
                    select.classList.remove('open');
                }
            }
        })

    }
}

