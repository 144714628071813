/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {

        /*
        |
        | Const
        |----------------
        |
        */
        const
            $body = $('body'),
            $mSection = $('.members-section'),
            $sImg = $('.team-section .card-img'),
            $cross = $('.cross-container'),
            $cursor = $('.cursor-container'),
            $sSlide = $('.team-slide')
            ;


        /*
        |
        | Pop In
        |----------------
        |
         */
        $sSlide.on('click', function (e) {
            var $index = $(this).index() + 1;
            var item_id = $(this).data("id")

            $('.members-container .member-slide').each(function () {
                var el = $(this)

                if (el.data("id") == item_id) {
                    el.addClass('active-member')
                }
            })
            $body.addClass('is-member');
            $mSection.addClass('active');
        })

        $cross.on('click', function (e) {
            e.preventDefault();

            $('.active-member').removeClass('active-member');
            $body.removeClass('is-member');
            $mSection.removeClass('active');
        })

    }
}
